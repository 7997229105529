<template>
  <template v-if="url">
    <NuxtLink :class="arrowStyles" :to="url">
      <slot />
      <DynamicPictogram name="chevron" class="rotate-180" />
    </NuxtLink>
  </template>
  <template v-else>
    <div :class="arrowStyles">
      <slot />
      <DynamicPictogram name="chevron" class="rotate-180" /></div
  ></template>
</template>
<script setup lang="ts">
defineProps({
  url: {
    type: String,
    default: '',
  },
});

const arrowStyles =
  'flex items-center gap-2 whitespace-nowrap py-2 text-blue-600 underline';
</script>
